<template>
  <div class="tingXiaHReport">
    <div class="box">
      <div class="title">{{ postData.topicTitle }}</div>
      <div class="body">
        <v-h5Mtml :content="postData.topicContent"></v-h5Mtml>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { getTopicInfoByIdURL } from "../api.js";
import { getHashParam } from "@/utils/utils.js";
import { gloabalCount, toRegister } from "@/utils/common";
var moment = require("moment");
export default {
  name: "tingXiaHReport",
  data() {
    return {
      postData: {},
    };
  },
  components: {},
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo(id) {
      let params = {
        id: this.id,
        userId: this.userId,
      };
      let res = await this.$axios.get(`${getTopicInfoByIdURL}`, {
        params: params,
      });
      if (res.code == 200) {
        this.postData = res.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tingXiaHReport {
  min-height: 100vh;
  background: url("./img/back.png") no-repeat;
  background-size: 100% 100%;
  .box {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: url("./img/box.png") no-repeat;
    background-size: 100% 100%;
    padding: 40px 32px;
    box-sizing: border-box;
    .title {
      font-weight: 700;
      font-size: 48px;
      color: #d92e2e;
      line-height: 66px;
      text-align: center;
      margin-bottom: 30px;
    }
  }
}
</style>
